<template>
	<div class="GoodsInfoSSS">
		
		<div class="Top">
			<div class="Left">
				关键词详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回列表</el-button>
				</span>
			</div>
		</div>
	
		
		<div class="List">
			
			<div class="One">
				<li class="Sel" v-if="WorkOrder.Id != 'add'">
					<span class="Sel">关键词组ID</span>
					<em class="Sel">
						{{WorkOrder.Id}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">关键词</span>
					<em class="Sel">
						{{WorkOrder.Keyword}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">查询次数</span>
					<em class="Sel">
						{{WorkOrder.Total}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">创建人ID</span>
					<em class="Sel">
						<i>{{WorkOrder.UserId}}</i>
					</em>
				</li>
			
				<li v-if="WorkOrder.Id != 'add'" class="Sel">
					<span class="Sel">创建时间</span>
					<em class="Sel">
						{{WorkOrder.CreatedAt}}
					</em>
				</li>
				
				<li v-if="WorkOrder.Id != 'add'" class="Sel">
					<span class="Sel">最后查询</span>
					<em class="Sel">
						{{WorkOrder.UpdatedAt}}
					</em>
				</li>
				
			</div>
			
			<div class="LogList" v-if="WorkOrder.Id != 'add'">
				<h4><i class="el-icon-chat-line-round"></i>搜索记录明细</h4>
				
				<li>
					<span>搜索时间</span>
					<em>用户Id</em>
				</li>
				
				<li v-for="(log,logI) in LogList" :key="logI">
					<span>{{log.CreatedAt}}</span>
					<em><a v-if="log.UserId != ''" target="_blank" :href="'/#/do/user/user_info/'+log.UserId">{{log.UserId}}</a></em>
				</li>
				<li>
					{{JSON.stringify(LogMore)}}
				</li>
				<p v-if="LogList.length%LogPageSize == 0">
					<em @click="GetLogList(LogPage+1)">
						加载更多
					</em>
				</p>
				
			</div>
			
		</div>
		
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import {Select,Option,Dialog,Upload,InputNumber} from 'element-ui'
	export default {
	  name: 'KeywordsInfo',
	  props: {
	  },
	  data() {
	      return {
			  WorkOrder:{
				  Id:'',
				  Keyword:'',
				  CreatedAt:'',
				  UpdatedAt:'',
				  UserId:'',
				  Total:0,
			  },
			  LogList:[],
			  LogPage:1,
			  LogPageSize:20,
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
		'el-input-number':InputNumber,
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少ID，页面无法工作')
			return
		}
		this.WorkOrder.Id = this.$route.params.Id
		this.GetWorkOrder(this.$route.params.Id)
		this.GetLogList(1)
	  },
	  methods:{
		 
		    GetWorkOrder(_id){
		  		let data = {Service:'Goods',Class: 'Keywords',Action: 'Get',Id:_id,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.WorkOrder = res.Data
		  		})
		    },
			GetLogList(_page){
				let that = this
				let data = {
					Service:'Goods',
					Class: 'Keywords',
					Action: 'LogList',
					KeywordsId:this.WorkOrder.Id,
					Page:_page,
					PageSize:this.LogPageSize,
				}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					if(res.ErrorId != 0){
						this.$message(res.Msg)
						return
					}
					this.LogPage = res.Data.CurrentPage
					if(res.Data.KeywordsLogList == undefined || res.Data.KeywordsLogList == null || res.Data.KeywordsLogList.length == 0){
						return
					}
					
					that.LogList = that.LogList.concat(res.Data.KeywordsLogList)
				
					
				})
			},
	  }
	}
</script>

<style>
.GoodsInfoSSS{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.GoodsInfoSSS .Top{
	display: flex;
	align-items: center;
}
.GoodsInfoSSS .Top .Left{
	
}
.GoodsInfoSSS .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.GoodsInfoSSS .List{
	margin-top: 20px;	
	display: flex;
}
.GoodsInfoSSS .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
	flex: 1;
}
.GoodsInfoSSS .List .One li.Sel{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.GoodsInfoSSS .List .One li.Sel span.Sel{
	width: 80px;
	color: rgba(0,0,0,0.3);
}
.GoodsInfoSSS .List .One li.Sel  em.Sel{
	flex: 1;
}
.GoodsInfoSSS .List .One li.Sel i.Sel{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}


.GoodsInfoSSS .List .LogList{
	flex: 1;
	margin-left: 10px;
	padding: 10px;
	background-color: rgba(0,0,0,0.04);
}
.GoodsInfoSSS .List .LogList h4{
	margin: 0px;
	padding: 10px 0px;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	font-size: 1.2rem;
}

.GoodsInfoSSS .List .LogList ul{
	background-color: rgba(0,0,0,0.04);
	padding: 10px;
}
.GoodsInfoSSS .List .LogList li{
	color: rgba(0,0,0,0.3);
	display: flex;
	border-bottom: 1px dotted rgba(0,0,0,0.04);
	padding: 5px 0px;
}
.GoodsInfoSSS .List .LogList li span{
	flex: 1;
}
.GoodsInfoSSS .List .LogList li em{
	flex: 1;
	color: rgba(0,0,0,0.4);
}
.GoodsInfoSSS .List .LogList li em a{
	color: rgba(0,0,0,0.4);
}
.GoodsInfoSSS .List .LogList li em a:hover{
	color: rgba(0,0,0,1);
}
.GoodsInfoSSS .List .LogList p{
	text-align: center;
	padding: 10px;
}
.GoodsInfoSSS .List .LogList p em{
	cursor: pointer;
}
</style>
